import React from 'react';

import { useSelector } from 'react-redux';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withBonnetPage } from '@bonnet/next/page';

import { pageNames } from '@atc/bonnet-paths';

import { AnalyticsProvider } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import fbaAnalyticsHandlers from '@/analytics/fbaAnalyticsHandlers';
import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import useAnalyticsPageEventListener from '@/analytics/useAnalyticsPageEventListener';
import useDataLayer from '@/analytics/useDataLayer';

import {
    addFordLandingPageData,
    ctxPresetFindCar,
    dispatchFordLandingPageData,
    withDataIslandDispatcher,
    withInterestRates,
} from '@/context-middleware';

import {
    withCrawlPath,
} from '@/context-middleware/flp';
import {
    withRequestOptions,
} from '@/context-middleware/srp';

import { birfDuck } from '@/ducks';

import * as flpDucks from '@/ducks/flp';

import {
    CanonicalUrlModule,
    CookieConsentModule,
    PersonalizationEngineModule,
} from '@/modules';

import {
    CommonHeadContainer,
    FordSEOContentContainer,
} from '@/containers';

import FbaPageEventsContainer from '@/containers/landing/FbaPageEventsContainer';
import HeroSearchBadgeContainer from '@/containers/landing/HeroSearchBadgeContainer';
import AnalyticsWrapper from '@/containers/sfp/AnalyticsWrapper';

const { FORD_LANDING } = pageNames;

function LandingPage({
    currentUrl,
    query,
}) {
    const {
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
        oneTrust: [isOneTrustEnabled],
    } = useFeatures([
        'disable_thirdparty',
        'google_analytics',
        'oneTrust',
    ]);
    const hasSuppressedCookies = useSelector(CookieConsentModule.duck.selectors.selectHasSuppressedGroup) && isOneTrustEnabled;
    const analyticsPageEventListener = useAnalyticsPageEventListener();
    const dataLayer = useDataLayer();
    const dataIsland = useSelector(birfDuck.selectors.getPageData);

    const analyticsProps = {
        disabled: hasSuppressedCookies,
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => ({ ...dataIsland }),
        google: {
            delay,
        },
        eventListener: analyticsPageEventListener,
        value: {
            ...globalAnalyticsHandlers,
            ...fbaAnalyticsHandlers,
        },
        option: {
            isOptimizelyEdge: true,
            disableGTM: disableGtm || disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            disablePixall: disableThirdParty,
        },
    };

    return (
        <>
            <CommonHeadContainer
                pageName={FORD_LANDING}
                currentUrl={currentUrl}
                query={query}
            />
            <AnalyticsProvider {...analyticsProps}>
                <FbaPageEventsContainer />
                <HeroSearchBadgeContainer />
                <div className="ford-landing-content-container">
                    <FordSEOContentContainer />
                </div>
                <AnalyticsWrapper />
            </AnalyticsProvider>
        </>
    );
}

LandingPage.getInitialProps = async (ctx) => {

    await withCtxMiddleware([
        ctxPresetFindCar({
            pageName: FORD_LANDING,
            preFetchCtxMiddleware: [
                withRequestOptions(),

            ],
            fetcher: addFordLandingPageData,
            dispatcher: dispatchFordLandingPageData,
        }),
        ctx.withModuleCtxMiddleware(),
        withCrawlPath(),
        withInterestRates(),
        withDataIslandDispatcher(),
    ], ctx);
    return {
        currentUrl: ctx.data.currentUrl,
        query: ctx.query,
    };
};

const { BonnetPageComponent } = withBonnetPage(LandingPage, {
    reducers: [
        ...Object.values(flpDucks),
    ],
    modules: [
        CanonicalUrlModule,
        CookieConsentModule,
        PersonalizationEngineModule,
    ],
});

export default BonnetPageComponent;
