import { memo } from 'react';

import { useSelector } from 'react-redux';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { getConsumerMetadata, sendPage } from 'reaxl-analytics';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

const FbaPageEventsContainer = memo(() => {
    const { onPageEnter } = usePageEvent('fbaPage', { priority: 1 });

    const peData = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization);
    const pePreferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences);

    onPageEnter(async () => {

        try {
            const consumerMetadata = await getConsumerMetadata();

            sendPage({
                data: {
                    peData,
                    pePreferences,
                    consumerMetadata,
                },
                isInitialLoad: true,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to send Page Event', error);
        }

    });

});

export default FbaPageEventsContainer;
